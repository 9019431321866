'use strict';

const SELECTORS = {
    swatchWrp: '.js-swatch-color',
    swatches: '.js-swatch-image',
    productTile: '.js-tile',
    imageContainer: '.js-tile-image',
    imageContainerUrl: '.js-tile-url',
    priceContainer: '.js-product-price'
};

/**
 * SwatchSelector class that handles the swatch color selection
 */
class SwatchSelector {
    constructor(element) {
        this.element = element;
        this.productTile = element.closest(SELECTORS.productTile);
        this.imageContainer = this.productTile.find(SELECTORS.imageContainer).eq(0);
        this.imageContainerUrl = this.productTile.find(SELECTORS.imageContainerUrl).eq(0);
        this.swatchSrc = element.attr('data-image');
        this.swatchAlt = element.attr('alt');
        this.swatchTitle = element.attr('title');
        this.href = element.closest('a').attr('href');
        this.priceObj = element.attr('data-price-obj');
        this.swatches = this.productTile.find(SELECTORS.swatches);
        this.firstSwatch = $(this.productTile).find('.js-wishlist-slider--selected').length > 0 ? $(this.productTile).find('.js-wishlist-slider--selected') : this.swatches.first();
        this.swatchesWrp = this.productTile.find(SELECTORS.swatchWrp);
        this.priceContainer = this.productTile.find(SELECTORS.priceContainer).eq(0);
        this.initEvents();
    }

    initEvents() {
        this.element.on('click', this.onSwatchClick.bind(this));
        this.swatchesWrp.removeClass('selected');
        this.firstSwatch.closest(SELECTORS.swatchWrp).addClass('selected');
        this.updateFirstSwatchAttributes();
        this.updateFirstSwatchPrice();
    }

    /**
     * Handles the swatch click, updates the selected state, and changes the image attributes
     */
    onSwatchClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.swatchesWrp.removeClass('selected');
        this.element.closest((SELECTORS.swatchWrp)).addClass('selected');
        this.updateImageAttributes();
        this.updatePrice();
    }
    /**
     * Updates the image container with the selected swatch's attributes
     */
    updateImageAttributes() {
        this.imageContainer.attr({
            src: this.swatchSrc,
            alt: this.swatchAlt,
            title: this.swatchTitle
        });
        this.imageContainerUrl.attr({
            href: this.href
        });
    }

    /**
     * Updates price template with selected swatch's attribute
     */
     updatePrice() {
        const price = JSON.parse(this.priceObj)
        const $priceContainer = this.productTile.find(SELECTORS.priceContainer).eq(0);
        if (price && price.renderedPrice) {
            $priceContainer.replaceWith(price.renderedPrice);
        }
    }

    /**
     * Updates the first swatch image container with the selected swatch's attributes
     */
    updateFirstSwatchAttributes() {
        this.imageContainer.attr({
            src: this.firstSwatch.attr('data-image'),
            alt: this.firstSwatch.attr('alt'),
            title: this.firstSwatch.attr('title')
        });
        /* this.imageContainerUrl.attr({
            href: this.firstSwatch.closest('a').attr('href')
        }); */
    }

    /**
     * Updates the first swatch price template with the selected swatch's attribute
     */
    updateFirstSwatchPrice() {
        var price = JSON.parse(this.firstSwatch.attr('data-price-obj'));
        if (price && price.renderedPrice) {
            this.priceContainer.replaceWith(price.renderedPrice);
        }
    }

}

module.exports = {
    initializeClass: function () {
        $(SELECTORS.swatches).each((index, element) => {
        new SwatchSelector($(element));
        });
    }
};
